import { gql } from "graphql-request";
import { type NextPage } from "next";
import Link from "next/link";
import {
  parseAsBoolean,
  parseAsInteger,
  parseAsString,
  useQueryState,
  useQueryStates,
} from "nuqs";
import { useEffect, useMemo, useRef } from "react";
import useSWR from "swr";

import Checkbox from "@/components/Checkbox";
import EmptyResultsMessage from "@/components/EmptyResultsMessage";
import Layout from "@/components/Layout";
import Pagination from "@/components/Pagination";
import Button from "@/components/sbb/Button";
import Search from "@/components/sbb/Search";
import Table from "@/components/sbb/Table";
import SearchForm from "@/components/SearchForm";
import Select from "@/components/Select";
import { currentUserGroupsFragment, isEditor } from "@/lib/currentUserGroups";
import { Phase, Portfolio, Region, Status } from "@/lib/graphql";
import transformQS from "@/lib/transformQS";
import useI18n from "@/lib/useI18n";

import type { PropertyTableQuery } from "@/lib/graphql";

const queryPropertyTable = gql`
  query propertyTable(
    $filters: PropertyFilter
    $order: PropertyOrder
    $pagination: PaginationInput
  ) {
    currentUser {
      ...currentUserGroups
    }
    properties(filters: $filters, order: $order, pagination: $pagination) {
      data {
        id
        phase
        portfolio
        region
        titleDe
        titleFr
        titleIt
      }
      meta {
        totalCount
      }
    }
  }
  ${currentUserGroupsFragment}
`;

const defaultFilters = {
  phase: parseAsString,
  portfolio: parseAsString,
  publishOnWebsite: parseAsBoolean,
  region: parseAsString,
  status: parseAsString.withDefault(Status.Active),
  titleDe: parseAsString,
  titleFr: parseAsString,
  titleIt: parseAsString,
};

const pageSize = 15;

const searchFieldName = {
  de: "titleDe",
  fr: "titleFr",
  it: "titleIt",
};

const PropertyIndexPage: NextPage = () => {
  const { activeLocale, enumToOptions, getLocaleTitle, t } = useI18n();
  const [filters, setFilters] = useQueryStates(defaultFilters);
  const [page, setPage] = useQueryState("page", parseAsInteger.withDefault(1));

  const order = useMemo(
    () => ({ [searchFieldName[activeLocale]]: "ASC" }),
    [activeLocale],
  );

  const { data } = useSWR<PropertyTableQuery>([
    queryPropertyTable,
    { filters: transformQS(filters), order, pagination: { page, pageSize } },
  ]);
  const prevActiveLocale = useRef(activeLocale);

  // Set status value as "active" by default on page load
  // Don't use nuqs lib to set a default value otherwise the
  // undefined choice will never be set.
  // see https://jira.geops.com/browse/PROPCMSW-15
  useEffect(() => {
    setFilters((f) => {
      return {
        ...f,
        status: f.status || Status.Active,
      };
    });
  }, [setFilters]);

  useEffect(() => {
    if (prevActiveLocale.current === activeLocale) return;
    setFilters((f) => ({
      ...f,
      [searchFieldName[activeLocale]]: f.titleDe || f.titleFr || f.titleIt,
      titleDe: null,
      titleFr: null,
      titleIt: null,
    }));
    prevActiveLocale.current = activeLocale;
  }, [activeLocale, setFilters]);

  return (
    <Layout>
      <div className="flex space-x-8">
        <div className="flex flex-col space-y-4" data-cy="property-search">
          <Search
            live
            onSearch={(s) => {
              setFilters((f) => ({
                ...f,
                [searchFieldName[activeLocale]]: s || null,
              }));
              setPage(1);
            }}
            placeholder={t("property.search")}
            resetButton
            value={filters.titleDe || filters.titleFr || filters.titleIt || ""}
          />
          <SearchForm
            className="flex flex-col space-y-4"
            onChange={(form) => {
              setFilters((f) => ({
                ...f,
                phase: form.phase || null,
                portfolio: form.portfolio || null,
                publishOnWebsite: form.publishOnWebsite || null,
                region: form.region || null,
                status: form.status || null,
              }));
              setPage(1);
            }}
            values={filters}
          >
            <Select
              data-cy="property-search-portfolio"
              label={t("property.portfolio")}
              name="portfolio"
              options={enumToOptions(Portfolio, "portfolio")}
            />
            <Select
              data-cy="property-search-phase"
              label={t("property.phase")}
              name="phase"
              options={enumToOptions(Phase, "phase")}
            />
            <Select
              data-cy="property-search-region"
              label={t("property.region")}
              name="region"
              options={enumToOptions(Region, "region")}
            />
            <Select
              data-cy="property-search-status"
              label={t("status.title")}
              name="status"
              options={enumToOptions(Status, "status")}
            />
            <Checkbox
              data-cy="property-search-publishOnWebsite"
              label={t("property.published")}
              name="publishOnWebsite"
            />
          </SearchForm>
          {isEditor(data?.currentUser) ? (
            <Link className="flex flex-col" href="/property/create">
              <Button data-cy="property-create" variant="secondary">
                {t("property.create")}
              </Button>
            </Link>
          ) : null}
        </div>
        <div className="w-full">
          <div
            className="flex w-full flex-col space-y-6"
            data-cy="properties-table"
          >
            <Table className="w-full table-fixed">
              <thead>
                <tr>
                  <Table.HeaderCell className="w-1/2">
                    {t("property.one")}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="w-[18%]">
                    {t("property.portfolio")}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="w-[14%]">
                    {t("property.region")}
                  </Table.HeaderCell>
                  <Table.HeaderCell className="w-[18%]">
                    {t("property.phase")}
                  </Table.HeaderCell>
                </tr>
              </thead>
              <tbody>
                {data?.properties?.data.map((prop) => (
                  <Table.Row key={prop.id}>
                    <Table.Cell className="truncate">
                      <Link
                        className="flex hover:text-red125"
                        href={`/property/${prop.id}/data`}
                      >
                        {getLocaleTitle(prop) || "-"}
                      </Link>
                    </Table.Cell>
                    <Table.Cell className="truncate">
                      {t(`portfolio.${prop.portfolio}`)}
                    </Table.Cell>
                    <Table.Cell className="truncate">
                      {t(`region.${prop.region}`)}
                    </Table.Cell>
                    <Table.Cell className="truncate">
                      {t(`phase.${prop.phase}`)}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </tbody>
            </Table>
            <EmptyResultsMessage
              visible={data?.properties?.meta.totalCount === 0}
            />
            {(data?.properties?.meta.totalCount || 0) > 0 && (
              <Pagination
                length={data?.properties?.meta.totalCount || 0}
                onChange={setPage}
                pageIndex={page}
                pageSize={pageSize}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PropertyIndexPage;
