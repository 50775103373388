import { gql } from "graphql-request";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";
import useSWR from "swr";

import { currentUserGroupsFragment, isAdmin } from "../lib/currentUserGroups";
import { type LayoutQuery } from "../lib/graphql";
import useI18n from "../lib/useI18n";

import Header from "./sbb/Header";
import Loading from "./sbb/Loading";
import Usermenu from "./sbb/Usermenu";

function getEnv(hostname?: string) {
  if (hostname?.includes("dev")) return "dev";
  else if (hostname?.includes("stag")) return "int";
}

const languages = { de: "Deutsch", fr: "Français", it: "Italiano" };

function AppUsermenu({
  currentUser,
}: {
  currentUser: LayoutQuery["currentUser"];
}) {
  const { activeLocale, locale, t } = useI18n();
  const router = useRouter();

  useEffect(() => {
    const { lang } = router.query;
    if (typeof lang === "string" && Object.hasOwn(languages, lang)) {
      locale(lang);
      router.replace(router.pathname, undefined, { shallow: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.lang]); // only run when lang query param changes

  return (
    <Usermenu
      data-cy="usermenu"
      displayName={`${currentUser.firstName} ${currentUser.lastName}`}
      loginText={t("login")}
    >
      {Object.entries(languages).map(([languageKey, languageLabel]) => (
        <Usermenu.Item
          active={activeLocale === languageKey}
          key={languageKey}
          onClick={() => locale(languageKey)}
        >
          {languageLabel}
        </Usermenu.Item>
      ))}
    </Usermenu>
  );
}

const query = gql`
  query layout {
    currentUser {
      firstName
      lastName
      ...currentUserGroups
    }
  }
  ${currentUserGroupsFragment}
`;

export default function Layout({
  children,
  className = "container mx-auto py-8 px-4 min-h-[calc(100vh-110px)]",
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const { t } = useI18n();
  const { asPath, pathname } = useRouter();
  const { data } = useSWR<LayoutQuery>(query);

  if (!data) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loading mode="big" />
      </div>
    );
  }
  return (
    <>
      <Header
        env={getEnv(globalThis.location?.hostname)}
        usermenu={<AppUsermenu currentUser={data.currentUser} />}
      >
        <Link
          className={`border-b px-2 py-4 hover:text-red125 ${
            pathname === "/" || asPath.startsWith("/property/")
              ? ""
              : "border-transparent"
          }`}
          href="/"
        >
          {t("property.other")}
        </Link>
        <Link
          className={`border-b px-2 py-4 hover:text-red125 ${
            asPath.startsWith("/contact") ? "" : "border-transparent"
          }`}
          href="/contact"
        >
          {t("contacts")}
        </Link>
        <Link
          className={`border-b px-2 py-4 hover:text-red125 ${
            asPath.startsWith("/media") ? "" : "border-transparent"
          }`}
          href="/media"
        >
          {t("mediaCenter")}
        </Link>
        {isAdmin(data.currentUser) ? (
          <Header.Menu button={t("admin.title")}>
            <Header.MenuItem href="/admin/core/user/" target="admin">
              {t("admin.user")}
            </Header.MenuItem>
          </Header.Menu>
        ) : null}
      </Header>
      <main className={className}>{children}</main>
      <footer className="border-t border-silver p-4 text-center text-sm text-granite">
        propCMS {process.env.NEXT_PUBLIC_CURRENT_VERSION}
      </footer>
    </>
  );
}
